import store from "@/store"
// 存缓存的方法
export const setItem = (key, value) => {
    if (typeof value == "object") {
        localStorage.setItem(key, JSON.stringify(value))
    } else {
        localStorage.setItem(key, value)
    }
}
// 读缓存的方法
export const getItem = (key) => {
    let obj = localStorage.getItem(key)
    try {
        return JSON.parse(obj)
    } catch (error) {
        return obj
    }
}

// 清空缓存的方法
export const removeStor = () => {
    store.commit("setToken", null)
}
// 清除指定缓存的方法
export const removeItem = (key) => {
    localStorage.removeItem(key)
}