
export default {
    namespaced: true,
    state: {
        opid: ''
    },
    mutations: {
        adder(state, id) {
            state.opid = id
        }
    },
    actions: {

    }
}