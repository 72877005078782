import {
	createRouter,
	createWebHistory,
	createWebHashHistory,
	RouteRecordRaw,
	useRouter
} from 'vue-router'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
const routes = [
	// 基础页
	{
		path: ``,
		name: 'index',
		component: () =>
			import("@/views/index"),
	},
	{
		path: "/store",
		name: 'store',
		component: () =>
			import("@/views/store"),
	},
	{
		path: "/cart",
		name: 'cart',
		component: () =>
			import("@/views/cart"),
	},
	{
		path: "/user",
		name: 'user',
		component: () =>
			import("@/views/user"),
	},
	// 辅具共享
	{
		path: '/share',
		name: 'share',
		component: () => import('@/views/share'),
	},
	// 辅具共享-商品
	{
		path: '/sharegood',
		name: 'sharegood',
		component: () => import('@/views/sharegood'),
	},
	// 辅具共享-商品cobe
	{
		path: '/sharegoods',
		name: 'sharegoods',
		component: () => import('@/views/sharegoods'),
	},
	// 租赁协议
	{
		path: '/protocol',
		name: 'protocol',
		component: () => import('@/views/protocol'),
	},
	// 支付押金
	{
		path: '/deposit',
		name: 'deposit',
		component: () => import('@/views/deposit'),
	},
	// 租赁成功
	{
		path: '/succeed',
		name: 'succeed',
		component: () => import('@/views/succeed'),
	},
	// 退押金
	{
		path: '/depositreturn',
		name: 'depositreturn',
		component: () => import('@/views/depositreturn'),
	},
	// 公司介绍
	{
		path: '/firm',
		name: 'firm',
		component: () => import('@/views/firm'),
	},
	// 产品介绍
	{
		path: '/productpresentation',
		name: 'productpresentation',
		component: () => import('@/views/productpresentation'),
	},
	// 产品介绍——详情
	{
		path: '/productdetails',
		name: 'productdetails',
		component: () => import('@/views/productdetails'),
	},
	// 计费规则
	{
		path: '/billingrule',
		name: 'billingrule',
		component: () => import('@/views/billingrule'),
	},
	// 搜索页
	{
		path: '/search',
		name: 'search',
		component: () => import('@/views/search'),
	},
	// 商城商品详情
	{
		path: '/gooddetails',
		name: 'gooddetails',
		component: () => import('@/views/gooddetails'),
	},
	// 确认订单
	{
		path: '/affindent',
		name: 'affindent',
		component: () => import('@/views/affindent'),
	},
	// 公告
	{
		path: '/notice',
		name: 'notice',
		component: () => import('@/views/notice'),
	},
	// 公告详情
	{
		path: '/noticeinfo',
		name: 'noticeinfo',
		component: () => import('@/views/noticeinfo'),
	},
	// 免责声明  
	{
		path: '/impunity',
		name: 'impunity',
		component: () => import('@/views/impunity'),
	},
	// 分享好友
	{
		path: '/invitation',
		name: 'invitation',
		component: () => import('@/views/invitation'),
	},
	// 收货地址
	{
		path: '/profile',
		name: 'profile',
		component: () => import('@/views/profile'),
	},
	// 新增地址
	{
		path: '/newprofile',
		name: 'newprofile',
		component: () => import('@/views/newprofile'),
	},
	// 残疾人认证
	{
		path: '/handicapped',
		name: 'handicapped',
		component: () => import('@/views/handicapped'),
	},
	// 商城订单
	{
		path: '/shoppindent',
		name: 'shoppindent',
		component: () => import('@/views/shoppindent'),
	},
	// 商城订单详情
	{
		path: '/shoppindentinfo',
		name: 'shoppindentinfo',
		component: () => import('@/views/shoppindentinfo'),
	},
	// 租赁订单
	{
		path: '/hireindent',
		name: 'hireindent',
		component: () => import('@/views/hireindent'),
	},
	// 审核管理          
	{
		path: '/auditmanage',
		name: 'auditmanage',
		component: () => import('@/views/auditmanage'),
	},
	// 认证详情
	{
		path: '/approve',
		name: 'approve',
		component: () => import('@/views/approve'),
	},
	{
		path: '/hireindent-on',
		name: 'hireindent-on',
		component: () => import('@/views/hireindent-on'),
	},
	{
		path: '/selShop-lease',
		name: '/selShop-lease',
		component: () => import('@/views/deliver/selShop'),
	}, {
		path: '/order-lease',
		name: '/order-lease',
		component: () => import('@/views/deliver/order'),
	}, {
		path: '/cashierDesk-lease',
		name: '/cashierDesk-lease',
		component: () => import('@/views/deliver/cashierDesk'),
	}, {
		path: '/paySuccess-lease',
		name: '/paySuccess-lease',
		component: () => import('@/views/deliver/paySuccess'),
	}, {
		path: '/mySubscribe',
		name: 'mySubscribe',
		component: () => import('@/views/deliver/mySubscribe'),
	}, {
		path: '/integral-list',
		name: 'integral-list',
		component: () => import('@/views/integral/integral-list'),
	},{
		path: '/map',
		name: 'mapt',
		component: () => import('@/views/deliver/map'),
	}

]

const router = createRouter({
	// history: createWebHistory(process.env.BASE_URL),
	history: createWebHashHistory(),
	routes
})


router.beforeEach((to, from, next) => {
	NProgress.start() // 进度条开始
	// 让页面回到顶部
	// chrome
	document.body.scrollTop = 0;
	// firefox
	document.documentElement.scrollTop = 0;
	// safari
	window.pageYOffset = 0;
	// 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
	next()
})

router.afterEach(() => {
	NProgress.done() // 进度条结束
})

export default router