import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
const app = createApp(App)
app.use(router)
// 组件注册引入
import navbar from './components/navbar/index.vue'
import tabbar from './components/tabbar/tabbar.vue'
// -----------------------------import { createApp } from 'vue';
// 1. 引入你需要的组件
import { Search, NavBar, Swipe, SwipeItem, Dialog, Popup, CountDown, Field, Icon, Toast, Picker, Uploader } from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css';
import '@/style/index.less'
createApp(App).use(store).use(router).component('tabbar', tabbar).component('navbar', navbar).use(Dialog).use(Uploader).use(CountDown).use(Field).use(Picker).use(Toast).use(Popup).use(Search).use(Swipe).use(SwipeItem).use(Icon).use(NavBar).mount('#app')
