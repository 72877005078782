import axios from 'axios'
import { getItem, removeItem } from './stotage'
import { showToast } from 'vant'


const request = axios.create({
    baseURL: 'https://fsl240322.zhiju001.com',
    // 接口超时时间
    timeout: 25000 // request timeout
})

// 在使用请求拦截器一定要 return config
// 添加请求拦截器
request.interceptors.request.use(
    config => {
        let token = getItem('token')
        config.headers.token = token
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
request.interceptors.response.use(
    response => {
        if (response.data.result) {
            return response.data.result
        } else {
            if (response.data.code == 1) {
                // showToast(response.data.msg);
            }
            if (response.data.code == 202) {
                // showToast(response.data.msg);
                // removeItem('token')
                // setTimeout(() => {
                //     window.location.href = '/'
                // }, 750);
            }
            return response
        }
    },
    error => {
        return Promise.reject(error)
    }
)

export default request
