<template>
    <van-nav-bar :title="title" left-arrow @click-left="onClickLeft">
        <template #left>
            <img src="@/assets/ic_returnblack.png" alt="" class="ic_returnblack" v-if="istype">
            <img src="@/assets/fjgx/ic_returnwhite.png" alt="" class="ic_returnblack" v-else>
        </template>
    </van-nav-bar>
</template>

<script setup>
const props = defineProps({
    title: String,
    istype: {
        type: Boolean,
        default: true
    }
})
const onClickLeft = () => history.back();
</script>

<style lang="less" scoped>
/deep/.van-nav-bar__left {
    padding: 0 0 0 4vw;
}

/deep/.ic_returnblack {
    width: 2.6667vw;
    height: 4.5333vw;
}

/deep/ .van-nav-bar .van-icon {
    color: #fff;
}

/deep/.van-nav-bar__title {
    font-size: 4.8vw;
    font-weight: 500;
    color: #000000;
}
</style>
