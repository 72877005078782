import request from '@/utils/request.js'
// 授权登录
export const login = (code) => {
    return request({
        url: '/api/login/miniWechatLogin',
        method: 'POST',
        data: {
            code: code
        }
    })
}
// 获取推广二维码
export const getShareCode = (params) => {
    return request({
        url: '/api/user/getShareCode',
        method: 'get',
        params
    })
}
// 获取用户信息
export const userinfo = (params) => {
    return request({
        url: '/api/user/index',
        method: 'get',
        params
    })
}
// 残疾认证
export const postUserInfoAdd = (data) => {
    return request({
        url: '/api/user/postUserInfoAdd',
        method: 'POST',
        data
    })
}
// 获取微信公众号参数
export const postSignPackage = (params) => {
    return request({
        url: '/api/index/postSignPackage',
        method: 'get',
        params
    })
}
// /获取首页数据
export const indexindex = (params) => {
    return request({
        url: '/api/index/index',
        method: 'get',
        params
    })
}