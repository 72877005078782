<template>
	<!-- <div style="width: 100%; height: 20vw;">
    </div> -->
	<div class="frame">
		<div class="box">
			<div class="item" :class="[intype == 0 ? 'colo' : '']" @click="add(0)">
				<div class="tupian">
					<img src="@/assets/tabbar/home.png" alt="" v-if="intype == 0">
					<img src="@/assets/tabbar/home_w.png" alt="" v-else>
				</div>
				<div>
					首页
				</div>
			</div>
			<div class="item" :class="[intype == 1 ? 'colo' : '']" @click="add(1)">
				<div class="tupian">
					<img src="@/assets/tabbar/shop.png" alt="" v-if="intype == 1">
					<img src="@/assets/tabbar/shop_w.png" alt="" v-else>
				</div>
				<div>
					商城
				</div>
			</div>
			<div class="item" :class="[intype == 2 ? 'colo' : '']" @click="add(2)">
				<div class="tupians">
				</div>
				<div class="ccent" :class="[intype == 2 ? 'colo' : '']">
					扫码租借
				</div>
			</div>
			<div class="item" :class="[intype == 3 ? 'colo' : '']" @click="add(3)">
				<div class="tupian">
					<img src="@/assets/tabbar/gouwuc.png" alt="" v-if="intype == 3">
					<img src="@/assets/tabbar/gouwuc_w.png" alt="" v-else>
				</div>
				<div>
					购物车
				</div>
			</div>
			<div class="item" :class="[intype == 4 ? 'colo' : '']" @click="add(4)">
				<div class="tupian">
					<img src="@/assets/tabbar/wode.png" alt="" v-if="intype == 4">
					<img src="@/assets/tabbar/wode_w.png" alt="" v-else>
				</div>
				<div>
					我的
				</div>
			</div>
			<!-- 突出半圆样式 -->
			<div class="dixianshi" @click="add(2)">
				<img src="@/assets/tabbar/saomzuj.png" alt="">
			</div>
		</div>
		<!-- 突出半圆样式 -->
		<div class="dibu">
			<div class="center">
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		ref
	} from 'vue'
	import {
		useRouter
	} from 'vue-router';
	import {
		postSignPackage
	} from '@/api/index'
	import {
		showToast
	} from 'vant';
	const router = useRouter()
	const type = ref(0)
	import wx from 'weixin-js-sdk'
	const add = (e) => {
		console.log(e);
		type.value = e
		if (e == 0) router.push('/')
		if (e == 1) router.push('/store')
		if (e == 2) {
			onScan()
		}
		if (e == 3) router.push('/cart')
		if (e == 4) router.push('/user')
	}
	const props = defineProps({
		title: String,
		intype: {
			type: Number,
			default: 0
		}
	})
	// 扫一扫
	const onScan = async () => {
		let arr = location.href.split("#")[0] //你的不带参的当前页url
		let {
			data
		} = await postSignPackage({
			weburl: arr
		})


		let {
			sign
		} = data.data
		// alert('扫一扫', data)
		// console.log(data);
		wx.config(sign);
		wx.error(function(res) {
			showToast(res.errMsg)
			// Dialog.alert({
			// 	title: "提示",
			// 	message: res.errMsg
			// }).then(() => {});
		});
		wx.ready(function() {
			wx.checkJsApi({
				jsApiList: ['scanQRCode'],
				success: function(res) {}
			});
			wx.scanQRCode({
				needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
				scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
				success: function(res) {
					var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
					console.log(result);
					// router.push({
					//     name: "reportDetail",
					//     query: { reportId: result }
					// });
				}
			});
		});
	}
</script>

<style lang="less" scoped>
	.frame {
		width: 100%;
		height: 16.5333vw;
		border-top: .1333vw solid #F2F2F2;
		position: fixed;
		bottom: 0;
		z-index: 99999999999999999;

		.box {
			font-size: 3.2vw;
			font-weight: 400;
			color: #999999;
			width: 100%;
			height: 16.5333vw;
			background-color: #ffffff;
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;

			// 每一项
			.item {
				flex: 1;
				display: flex;
				flex-direction: column;
				align-items: center;

				// 图标大小
				.tupian {
					width: 5.4667vw;
					height: 5.4667vw;
					margin-bottom: 1.0667vw;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.tupians {
					width: 5.4667vw;
					height: 5.4667vw;
					margin-bottom: 1.0667vw;
				}

				.ccent {
					font-size: 3.2vw;
					font-weight: 500;
					color: #000000;
				}

				.colo {
					color: #00B363;
				}

			}

			.colo {
				color: #00B363;
			}

			.dixianshi {
				width: 13.2vw;
				height: 13.2vw;
				border-radius: 50%;
				position: absolute;
				top: -4.6667vw;
				left: 50%;
				transform: translateX(-50%);
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					width: 13.2vw;
					height: 13.2vw;
					border-radius: 50%;
				}
			}
		}

		.dibu {
			position: absolute;
			top: -4.6667vw;
			left: 50%;
			transform: translateX(-50%);
			width: 13.3333vw;
			height: 13.3333vw;
			border-radius: 50%;
			border: .1333vw solid #F2F2F2;
			z-index: -1;

			.center {
				width: 100%;
				height: 100%;
				border-radius: 50%;
				background-color: #ffffff;
			}
		}
	}
</style>