import { createStore } from 'vuex'
import user from './modules/user'
import family from './modules/family'

export default createStore({
  modules: {
    user,
    family,
  },
})
